<template>
  <div>
    <component :is="layout">
      <router-view :layout.sync="layout"/>
    </component>
    <vue-snotify/>

    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
  </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import eventHub from '@/eventhub'
import notificationMixin from '@/mixins/notificationMixin'

const defaultLayout = 'main-layout'

export default {
  name: 'App',
  mixins: [notificationMixin],
  data () {
    return {
      isLoading: false,
      fullPage: true
    }
  },
  created () {
    eventHub.$on('error_503', () => {
      this.showError('Servicio no disponible')
    })

    eventHub.$on('error_401', () => {
      this.$router.push({ name: 'error-401' })
    })

    eventHub.$on('error_404', () => {
      this.$router.push({ name: 'error-404' })
    })

    eventHub.$on('error_403', () => {
      this.$router.push({ name: 'error-403' })
    })

    eventHub.$on('show_loader', () => {
      this.isLoading = true
    })

    eventHub.$on('hide_loader', () => {
      this.isLoading = false
    })
  },
  components: {
    Loading
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout)
    }
  }
}
</script>
