import rolesConstants from '../constants/RolesConstants'

const sessionMixin = {
  methods: {
    saveToLocalStorage (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    getUser () {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo && userInfo.user) {
        return {
          username: userInfo.user.username,
          token: userInfo.token,
          roleId: userInfo.user.roleId,
          bookingId: userInfo.user.bookingId,
          fairId: (userInfo.user.booking && userInfo.user.booking.company)
            ? userInfo.user.booking.company.fairId : null,
          fairName: userInfo.user.fairName ? userInfo.user.fairName : null,
          fairSlug: userInfo.user.fairSlug ? userInfo.user.fairSlug : null,
          companyName: userInfo.user.companyName ? userInfo.user.companyName : null,
          resetPassword: userInfo.user.reset_password
        }
      }
      return null
    },
    getRoleById (roleId) {
      for (const roleName in rolesConstants) {
        const role = rolesConstants[roleName]
        if (roleId === role.code) {
          return role
        }
      }
      return null
    },
    isCustomer (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.customer.code
    },
    isAdmin (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.admin.code
    },
    isSuperAdmin (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.superadmin.code
    },
    isSuperExpositorAdmin (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.superExpositorAdmin.code
    },
    isExpositorAdmin (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.expositorAdmin.code
    },
    isConferenceAdmin (roleId) {
      const role = this.getRoleById(roleId)
      return role.code === rolesConstants.conferenceAdmin.code
    },
    isLoggedIn () {
      return this.getUser() != null
    },
    clear () {
      localStorage.clear()
    },
    hasPermission (permissionCode) {
      const user = this.getUser()
      if (user) {
        const role = this.getRoleById(user.roleId)
        if (role) {
          return role.permissions.includes(permissionCode)
        }
      }
      return false
    }
  }
}

export default sessionMixin
