export const MENU_ITEMS_CONSTANTS = [
  {
    text: 'Ferias',
    name: 'fairs',
    to: '/ferias'
  },
  {
    text: 'Reservas',
    name: 'bookings',
    to: '/reservas'
  },
  {
    text: 'Reporte',
    name: 'fairReport',
    to: '/reporte-ferias'
  },
  {
    text: 'Gestor de Usuarios APP',
    name: 'expositors',
    to: '/expositores/gestion-usuarios'
  },
  {
    text: 'Mis Visitantes',
    name: 'visitors',
    to: '/expositores/visitantes'
  },
  {
    text: 'Mi reserva',
    name: 'bookingConfirm',
    to: '/'
  },
  {
    text: 'Charlas técnicas',
    name: 'conferenceBookings',
    to: '/'
  }
]
export default MENU_ITEMS_CONSTANTS
