import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

const phoneNumber = require('awesome-phonenumber')
export default {
  install (Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule])
    })

    extend('phone', {
      computesRequired: false,
      validate: value => {
        // eslint-disable-next-line new-cap
        const phone = new phoneNumber(value)
        return phone.isValid()
      },
      message: 'Formato de teléfono inválido, Ejemplos: +57 4 2002536 +57 3007005050'
    })

    extend('minSize', {
      computesRequired: true,
      validate: (value, args) => {
        return value && value.length >= args.length
      },
      params: ['length'],
      message: 'Debes seleccionar al menos un item'
    })

    extend('maxSize', {
      computesRequired: true,
      validate: (value, args) => {
        return value && value.length <= args.length
      },
      params: ['length'],
      message: 'Puedes seleccionar máximo {length} items'
    })

    extend('nit', {
      computesRequired: true,
      validate: (value) => {
        return value.match(/^[A-Za-z0-9]+$/)
      },
      message: 'El NIT no puede contener caracteres especiales como puntos y/o comas.'
    })

    extend('firstContactEmail', {
      validate: (value, { secondContactEmail }) => {
        return value && value !== secondContactEmail
      },
      params: ['secondContactEmail'],
      message: 'El correo electrónico del primer contacto debe ser diferente al del segundo contacto.'
    })

    extend('secondContactEmail', {
      validate: (value, { firstContactEmail }) => {
        return value && value !== firstContactEmail
      },
      params: ['firstContactEmail'],
      message: 'El correo electrónico del segundo contacto debe ser diferente al del primer contacto.'
    })

    extend('forbiddenUsernames', {
      validate: (value) => {
        const forbiddenUsernames = [
          'abuse',
          'admin',
          'avidandlb',
          'billing',
          'compliance',
          'devnull',
          'dns',
          'ftp',
          'hostmaster',
          'inoc',
          'ispfeedback',
          'ispsupport',
          'list',
          'list-request',
          'maildaemon',
          'noc',
          'noreplyno-reply',
          'null',
          'phish',
          'phishing',
          'popmaster',
          'postmaster',
          'privacy',
          'registrar',
          'root',
          'security',
          'soporte',
          'spam',
          'support',
          'sysadmin',
          'tech',
          'undisclosed-recipients',
          'unsubscribe',
          'usenet',
          'uucp',
          'webmaster',
          'webmasters',
          'www'
        ]
        return value && value.split('@')[0] && !forbiddenUsernames.includes(value.split('@')[0])
      },
      message: 'Este nombre de correo electrónico no está permitido.'
    })

    extend('verifyPassword', {
      validate: value => {
        const strongRegex = new RegExp(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
        return strongRegex.test(value)
      },
      message: () => 'La contraseña debe contener al menos: letras mayúsculas, letras minúsculas y dígitos'
    })

    localize('es', es)
  }
}
