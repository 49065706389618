import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/public/security/Login.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/login-expositor/:slug',
    name: 'loginExpositor',
    component: () => import('@/views/public/security/LoginExpositor.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/password-reset/:slug',
    name: 'passwordReset',
    component: () => import('@/views/public/security/PasswordReset.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/password-reset-confirm',
    name: 'passwordResetConfirm',
    component: () => import('@/views/private/password-reset/PasswordResetConfirm.vue'),
    meta: { layout: 'full-width-layout', private: true }
  },
  {
    path: '/ferias',
    name: 'fairs',
    component: () => import('@/views/private/fairs/Fairs.vue'),
    meta: { private: true }
  },
  {
    path: '/ferias/:slug/nuestros-expositores',
    name: 'ourExpositors',
    component: () => import('@/views/public/expositors/Expositors.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/ferias/:slug/seleccion-stands',
    name: 'selection',
    component: () => import('@/views/public/booking/StandSelection.vue'),
    meta: { private: false }
  },
  {
    path: '/ferias/:fairId/reservas/:id/confirmar',
    name: 'bookingConfirm',
    component: () => import('@/views/private/booking-confirmation/BookingConfirmation.vue'),
    meta: { private: false }
  },
  {
    path: '/ferias/:id/charlas',
    name: 'conferences',
    component: () => import('@/views/private/conferences/Conferences.vue'),
    meta: { layout: 'basic-layout', private: true }
  },
  {
    path: '/reservas',
    name: 'bookings',
    component: () => import('@/views/private/bookings/Bookings.vue'),
    meta: { private: true }
  },
  {
    path: '/ferias/:fairId/reservas/:id',
    name: 'bookingEdit',
    component: () => import('@/views/private/booking/Booking.vue'),
    meta: { private: true }
  },
  {
    path: '/ferias/:id/lugares',
    name: 'places',
    component: () => import('@/views/private/places/Places.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/lugares/:id/stands',
    name: 'stands',
    component: () => import('@/views/private/stands/Stands.vue'),
    meta: { private: true }
  },
  {
    path: '/ferias/:fairId/configuracion-general',
    name: 'generalSettings',
    component: () => import('@/views/private/general-settings/GeneralSettings.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/admin-expositores',
    name: 'manageAdminExpositors',
    component: () => import('@/views/private/admin-expositors/AdminExpositors.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/admin-visitantes',
    name: 'manageVisitors',
    component: () => import('@/views/private/admin-visitors/AdminVisitors.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/programacion-general',
    name: 'generalActivities',
    component: () => import('@/views/private/general-activities/GeneralActivities.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/evento-academico',
    name: 'academicActivities',
    component: () => import('@/views/private/academic-activities/AcademicActivities.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/restaurantes',
    name: 'restaurants',
    component: () => import('@/views/private/restaurants/Restaurants.vue'),
    meta: { private: true, layout: 'basic-layout' }
  },
  {
    path: '/ferias/:fairId/informacion-general',
    name: 'generalInformation',
    component: () => import('@/views/private/general-information/GeneralInformation.vue'),
    meta: { layout: 'basic-layout', private: true }
  },
  {
    path: '/ferias/:fairId/sitios-interes',
    name: 'interestPlaces',
    component: () => import('@/views/private/interest-places/InterestPlaces.vue'),
    meta: { layout: 'basic-layout', private: true }
  },
  {
    path: '/ferias/:fairId/configuracion-lugares',
    name: 'placesSettings',
    component: () => import('@/views/private/places-settings/PlacesSettings.vue'),
    meta: { layout: 'basic-layout', private: true }
  },
  {
    path: '/ferias/:slug/charlas-tecnicas',
    name: 'conferences',
    component: () => import('@/views/public/conferences/Conferences.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/reporte-ferias',
    name: 'fairReport',
    component: () => import('@/views/private/fair-report/FairReport.vue'),
    meta: { private: true }
  },
  {
    path: '/expositores/reserva-charlas',
    name: 'conferenceBookings',
    component: () => import('@/views/private/conference-bookings/ConferenceBookings.vue'),
    meta: { private: true }
  },
  {
    path: '/expositores/visitantes',
    name: 'visitors',
    component: () => import('@/views/private/visitors/Visitors.vue'),
    meta: { private: true }
  },
  {
    path: '/expositores/gestion-usuarios',
    name: 'expositors',
    component: () => import('@/views/private/expositors/Expositors.vue'),
    meta: { private: true }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/pages/Error403.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/pages/Error401.vue'),
    meta: { layout: 'full-width-layout', private: false }
  },
  {
    path: '*',
    redirect: '/error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, source, next) => {
  let authenticated = false

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (userInfo) {
    if (userInfo.token) {
      authenticated = true
      if (to.fullPath !== '/password-reset-confirm' && userInfo.user.reset_password === 1) {
        return next({ name: 'passwordResetConfirm' })
      }
    }
  }

  if (to.meta.private && !authenticated) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
