import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import AxiosPlugin from './plugins/axios'
import VeeValidatePlugin from './plugins/vee-validate'
import MainLayout from './layouts/MainLayout'
import FullWidthLayout from './layouts/FullWidthLayout'
import Snotify from 'vue-snotify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import '@/assets/scss/main.scss'
import 'vue-snotify/styles/material.css'
import '@fortawesome/fontawesome-free/css/all.css'
import VueKonva from 'vue-konva'
import VueCurrencyFilter from 'vue-currency-filter'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import BasicLayout from './layouts/BasicLayout'

Vue.use(AxiosPlugin)
Vue.use(VeeValidatePlugin)
Vue.use(Snotify)
Vue.use(VueTelInputVuetify, {
  vuetify
})
Vue.use(VueCurrencyFilter,
  {
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  })
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'es-CO',
  decimalLength: 0,
  autoDecimalMode: false,
  min: 0,
  max: 1000000000,
  defaultValue: 0,
  valueAsInteger: true,
  allowNegative: false
})
Vue.use(VueKonva, { prefix: 'Konva' })

// Layouts
Vue.component('main-layout', MainLayout)
Vue.component('full-width-layout', FullWidthLayout)
Vue.component('basic-layout', BasicLayout)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
