import axios from 'axios'
import eventHub from '@/eventhub'

function redirectToErrorResponse (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 401:
        if (!error.response.config.url.includes('login')) {
          localStorage.clear()
          eventHub.$emit('error_401')
        }
        break
      case 404:
        eventHub.$emit('error_404')
        break
      case 403:
        eventHub.$emit('error_403')
        break
    }
  } else {
    eventHub.$emit('error_503')
  }
}

const axiosFiseApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosFiseApi.interceptors.request.use((config) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = (userInfo) ? userInfo.token : ''
  config.headers.common = {
    [process.env.VUE_APP_API_TOKEN_HEADER]: `${process.env.VUE_APP_API_TOKEN_PREFIX}` + ' ' + token
  }
  return config
})

axiosFiseApi.interceptors.response.use((config) => config, (error) => {
  redirectToErrorResponse(error)
  return Promise.reject(error)
})

const axiosApiMobile = axios.create({
  baseURL: process.env.VUE_APP_API_MOBILE_URL
})

axiosApiMobile.interceptors.request.use((config) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = (userInfo) ? userInfo.token : ''
  config.headers.common = {
    [process.env.VUE_APP_API_TOKEN_HEADER]: `${process.env.VUE_APP_API_TOKEN_PREFIX}` + ' ' + token
  }
  return config
})

axiosApiMobile.interceptors.response.use((config) => config, (error) => {
  redirectToErrorResponse(error)
  return Promise.reject(error)
})

export default {
  install (Vue) {
    Vue.prototype.$axiosFiseApi = axiosFiseApi
    Vue.prototype.$axiosApiMobile = axiosApiMobile
  }
}
